import {
  OFFER_TYPE_CARHIRE_SLUG,
  OFFER_TYPE_CRUISE_SLUG,
  OFFER_TYPE_HOTEL_SLUG,
  OFFER_TYPE_TOUR_SLUG,
  PRODUCT_TYPE_ULTRALUX_SLUG,
} from 'constants/offer'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { ASIAN_COUNTRIES } from './region'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import config from '.'

const HOTELS_NAV_ITEM: App.NavItem = {
  to: `/${OFFER_TYPE_HOTEL_SLUG}`,
  id: `nav-link-${OFFER_TYPE_HOTEL_SLUG}`,
  text: 'Hotels',
  testId: 'nav-link-hotels',
  iconName: 'bed',
  mobileNav: true,
  category: 'primary',
}

const TOURS_NAV_ITEM = (env: App.EnvVarsAndFeatures): App.NavItem => ({
  to: `/${OFFER_TYPE_TOUR_SLUG}`,
  id: `nav-link-${OFFER_TYPE_TOUR_SLUG}`,
  text: 'Tours',
  testId: 'nav-link-tours',
  onlyOnRegions: env.STANDALONE_CRUISE_REGIONS?.split(',') ?? [],
  mobileNav: true,
  iconName: 'compass',
  category: 'primary',
})

const TOURS_CRUISES_NAV_ITEM = (env: App.EnvVarsAndFeatures): App.NavItem => ({
  to: `/${OFFER_TYPE_TOUR_SLUG}`,
  id: `nav-link-${OFFER_TYPE_TOUR_SLUG}`,
  text: 'Tours & Cruises',
  testId: 'nav-link-tours',
  notOnRegions: env.STANDALONE_CRUISE_REGIONS?.split(',') ?? [],
  mobileNav: true,
  iconName: 'compass',
  category: 'primary',
})

const CRUISES_NAV_ITEM = (env: App.EnvVarsAndFeatures): App.NavItem => ({
  to: `/${OFFER_TYPE_CRUISE_SLUG}`,
  id: `nav-link-${OFFER_TYPE_CRUISE_SLUG}`,
  text: 'Cruises',
  onlyOnRegions: env.STANDALONE_CRUISE_REGIONS?.split(','),
  mobileNav: true,
  testId: 'nav-link-cruises',
  featureToggle: 'CRUISES_STANDLONE_ENABLED',
  iconName: 'ship',

  category: 'primary',
})

const FLIGHTS_NAV_ITEM: App.NavItem = {
  to: '/flights',
  id: 'nav-link-flights',
  text: 'Flights',
  onlyOnRegions: ['AU', 'US', 'NZ', 'GB', 'IN', 'SG', 'HK'],
  mobileNav: true,
  iconName: 'plane-departure',
  category: 'primary',
}

const CAR_HIRE_NAV_ITEM = (env: App.EnvVarsAndFeatures): App.NavItem => ({
  to: `/${OFFER_TYPE_CARHIRE_SLUG}`,
  id: `nav-link-${OFFER_TYPE_CARHIRE_SLUG}`,
  text: 'Car Hire',
  testId: 'nav-link-car-hire',
  onlyOnRegions: env.CARHIRE_REGIONS.split(','),
  mobileNav: true,
  iconName: 'car',
  featureToggle: 'CARHIRE_ENABLED',
  category: 'primary',
})

const ULTRA_LUX_NAV_ITEM: App.NavItem = {
  to: `/${PRODUCT_TYPE_ULTRALUX_SLUG}`,
  id: 'nav-link-ultralux',
  text: 'Ultra Lux',
  mobileNav: true,
  testId: 'nav-link-ultralux',
  featureToggle: 'ULTRALUX_ENABLED',
  iconName: 'ultra-lux-diamond',
  category: 'primary',
}

const EXPERIENCES_NAV_ITEM: App.NavItem = {
  to: '/experiences',
  id: 'nav-link-experiences',
  text: 'Experiences',
  notOnRegions: ASIAN_COUNTRIES.filter(c => !['SG', 'ID', 'TH', 'VN', 'AE'].includes(c)),
  mobileNav: true,
  iconName: 'hot-air-balloon',
  category: 'primary',
}

const LUXURY_PLUS_NAV_ITEM: App.NavItem = {
  to: `/${LUXURY_PLUS_PATHS.LANDING_PAGE}`,
  id: 'nav-link-luxury-plus',
  newLabel: true,
  text: LUXURY_PLUS.PROGRAM_NAME,
  mobileNav: true,
  luxPlusToggle: 'luxPlusEnabled',
  sideNavIconName: 'plus',
  category: 'secondary',
}

const LUX_LOYALTY_NAV_ITEM: App.NavItem = {
  to: `/${LUX_LOYALTY.LANDING_PAGE}`,
  id: 'nav-link-lux-loyalty',
  newLabel: true,
  text: LUX_LOYALTY.PROGRAM_NAME,
  mobileNav: true,
  luxLoyaltyToggle: 'luxLoyaltyToggle',
  category: 'secondary',
}

const INSPIRATION_NAV_ITEM: App.NavItem = {
  href: 'https://luxuryescapes.com/inspiration/',
  target: '_blank',
  id: 'nav-link-inspiration',
  text: 'Inspiration',
  testId: 'nav-link-inspiration',
  hideInStoreMode: true,
  mobileNav: true,
  component: 'secondary',
  category: 'tertiary',
}

const SUPPORT_NAV_ITEM: App.NavItem = {
  to: '/support',
  id: 'nav-link-support',
  text: 'Support',
  mobileNav: true,
  hideInStoreMode: true,
  component: 'secondary',
  category: 'tertiary',
}

const KRISFLYER_NAV_ITEM: App.NavItem = {
  to: '/krisflyer',
  id: 'nav-link-krisflyer',
  text: 'Earn KrisFlyer miles',
  onlyOnRegions: ['SG', 'HK', 'MY', 'TH', 'ID', 'PH', 'VN', 'CN', 'JP', 'KR', 'TW'],
  category: 'tertiary',
}

const GIFT_CARD_NAV_ITEM: App.NavItem = {
  to: '/gift-cards',
  id: 'nav-link-gift-cards',
  text: 'Gift Cards',
  testId: 'nav-link-gift-cards',
  mobileNav: true,
  iconName: 'gift',
  category: 'secondary',
}

const INSURANCE_NAV_ITEM: App.NavItem = {
  to: '/travel-protection',
  id: 'nav-link-insurance',
  text: 'Travel Protection',
  onlyOnRegions: ['AU', 'SG', 'HK', 'IN', 'MY', 'TH'],
  category: 'secondary',
}

const REFER_NAV_ITEM: App.NavItem = {
  to: '/refer',
  id: 'nav-link-refer',
  text: 'Refer a Friend',
  accountToggle: 'referralProgramEnabled',
  testId: 'nav-link-refer',
  category: 'tertiary',
}

const VELOCITY_NAV_ITEM: App.NavItem = {
  to: '/velocity-frequent-flyer',
  id: 'velocity-frequent-flyer',
  text: 'Velocity Frequent Flyer',
  onlyOnRegions: ['AU'],
  category: 'tertiary',
}

const LE4B_NAV_ITEM: App.NavItem = {
  href: 'https://lebusinesstraveller.com/',
  target: '_blank',
  id: 'nav-link-le4b',
  text: 'Business Travel',
  onlyOnRegions: ['AU'],
  category: 'tertiary',
}

const WEDDINGS_NAV_ITEM: App.NavItem = {
  href: 'https://luxuryescapes.com/inspiration/group-bookings/',
  target: '_blank',
  id: 'nav-link-destination-weddings',
  text: 'Groups and Weddings',
  mobileNav: true,
  category: 'tertiary',
}

const MORE_DROPDOWN_NAV_ITEM = (items: Array<App.NavItem>): App.NavItem => ({
  id: 'nav-link-more',
  text: 'More',
  hasDropdown: true,
  testId: 'nav-link-more',
  mobileNav: true,
  hideOnTablet: true,
  component: 'secondary',
  items,
  category: 'tertiary',
})

export function getNavItemsFlat(): Array<App.NavItem> {
  return ([
    HOTELS_NAV_ITEM,
    FLIGHTS_NAV_ITEM,
    TOURS_NAV_ITEM(config),
    CRUISES_NAV_ITEM(config),
    TOURS_CRUISES_NAV_ITEM(config),
    ULTRA_LUX_NAV_ITEM,
    EXPERIENCES_NAV_ITEM,
    CAR_HIRE_NAV_ITEM(config),
    INSPIRATION_NAV_ITEM,
    {
      ...GIFT_CARD_NAV_ITEM,
      // flat doesn't watch gift with an icon
      iconName: undefined,
    },
    SUPPORT_NAV_ITEM,
    INSURANCE_NAV_ITEM,
    REFER_NAV_ITEM,
    WEDDINGS_NAV_ITEM,
    LE4B_NAV_ITEM,
    VELOCITY_NAV_ITEM,
  ])
}

export function getOrderedItemsByRegion(env: App.EnvVarsAndFeatures): Record<string, Array<App.NavItem>> {
  return {
    AU: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_NAV_ITEM(env),
      CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        INSURANCE_NAV_ITEM,
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
        LE4B_NAV_ITEM,
        VELOCITY_NAV_ITEM,
      ]),
    ],
    NZ: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_NAV_ITEM(env),
      CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
      ]),
    ],
    GB: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
      ]),
    ],
    US: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
      ]),
    ],
    SG: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        REFER_NAV_ITEM,
        KRISFLYER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
      ]),
    ],
    IN: [
      HOTELS_NAV_ITEM,
      TOURS_CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        GIFT_CARD_NAV_ITEM,
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
      ]),
    ],
    DEFAULT: [
      HOTELS_NAV_ITEM,
      LUXURY_PLUS_NAV_ITEM,
      LUX_LOYALTY_NAV_ITEM,
      FLIGHTS_NAV_ITEM,
      TOURS_NAV_ITEM(env),
      CRUISES_NAV_ITEM(env),
      TOURS_CRUISES_NAV_ITEM(env),
      ULTRA_LUX_NAV_ITEM,
      EXPERIENCES_NAV_ITEM,
      GIFT_CARD_NAV_ITEM,
      INSPIRATION_NAV_ITEM,
      SUPPORT_NAV_ITEM,
      MORE_DROPDOWN_NAV_ITEM([
        CAR_HIRE_NAV_ITEM(env),
        INSURANCE_NAV_ITEM,
        REFER_NAV_ITEM,
        WEDDINGS_NAV_ITEM,
        VELOCITY_NAV_ITEM,
      ]),
    ],
  }
}

// This is only used by for three brands: cudotravel, dealstravel, scoopontravel
export function getWhiteLabelsCommonNavItems(env: App.EnvVarsAndFeatures): Array<App.NavItem> {
  const items: Array<App.NavItem> = [
    {
      id: 'hotelsandresorts',
      to: `/${OFFER_TYPE_HOTEL_SLUG}`,
      text: 'Hotels & Resorts',
      iconName: 'bed',
      mobileNav: true,
      category: 'primary',
    },
    ...env.ENABLE_STANDALONE_FLIGHTS ? [{
      to: '/flights',
      id: 'flights',
      text: 'Flights',
      onlyOnRegions: ['AU', 'US', 'NZ', 'HK', 'GB'],
      newLabel: true,
      newLabelOnlyOnRegions: ['AU', 'US', 'NZ', 'HK', 'GB'],
      iconName: 'plane-departure',
      mobileNav: true,
      category: 'primary',
    } as App.NavItem] : [],
    ...env.EXPERIENCES_STANDLONE_ENABLED ? [{
      id: 'experiences',
      to: '/experiences',
      text: 'Experiences',
      iconName: 'hot-air-balloon',
      mobileNav: true,
      category: 'primary' as App.NavItemCategory,
    } as App.NavItem] : [],
    {
      to: `/${OFFER_TYPE_TOUR_SLUG}`,
      id: `nav-link-${OFFER_TYPE_TOUR_SLUG}`,
      text: 'Tours',
      testId: 'nav-link-tours',
      onlyOnRegions: env.CRUISES_REGIONS?.split(',') ?? [],
      iconName: 'compass',
      mobileNav: true,
      category: 'primary',
    },
    {
      to: `/${OFFER_TYPE_CRUISE_SLUG}`,
      id: `nav-link-${OFFER_TYPE_CRUISE_SLUG}`,
      text: 'Cruises',
      onlyOnRegions: env.CRUISES_REGIONS?.split(','),
      iconName: 'ship',
      mobileNav: true,
      testId: 'nav-link-cruises',
      featureToggle: 'CRUISES_STANDLONE_ENABLED',
      category: 'primary',
    },
    {
      to: `/${OFFER_TYPE_CARHIRE_SLUG}`,
      id: `nav-link-${OFFER_TYPE_CARHIRE_SLUG}`,
      newLabel: true,
      text: 'Car Hire',
      testId: 'nav-link-car-hire',
      onlyOnRegions: env.CARHIRE_REGIONS?.split(','),
      mobileNav: true,
      iconName: 'car',
      featureToggle: 'CARHIRE_ENABLED',
      category: 'primary',
    },
    {
      to: `/${OFFER_TYPE_TOUR_SLUG}`,
      id: `nav-link-${OFFER_TYPE_TOUR_SLUG}`,
      text: 'Tours & Cruises',
      testId: 'nav-link-tours',
      notOnRegions: env.CRUISES_REGIONS?.split(',') ?? [],
      iconName: 'compass',
      mobileNav: true,
      category: 'primary',
    },
    ...env.ENABLE_GIFT_CARD ? [{
      to: '/gift-cards',
      id: 'nav-link-gift-cards',
      text: 'Gift Cards',
      mobileNav: true,
      newLabel: true,
      category: 'secondary',
    } as App.NavItem] : [],
    ...env.SUPPORT_ENABLED ? [{
      to: '/support',
      id: 'nav-link-support',
      text: 'Support',
      mobileNav: true,
      hideInStoreMode: true,
      category: 'tertiary',
    } as App.NavItem] : [],
  ]
  return items
}
